import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { STRETCH_PLAN } from '../constants/resourceId';

const SETTING_INCOMPLETE = '2';
const IN_PROGRESS_CASE = '3';
const OVERDUE_CASE = '4';
const COMPLETE = '5';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.stretchPlanId === SETTING_INCOMPLETE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 2,
          operationCycle: '運用設定名1',
          status: '設定未完了',
          startedAt: '2050-01-01T00:00:00+09:00',
          endedAt: '2051-01-01T23:59:59+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === IN_PROGRESS_CASE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 3,
          operationCycle: '運用設定名1',
          status: '実施中',
          startedAt: '2023-02-13T00:00:00+09:00',
          endedAt: '2023-03-05T23:59:59+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === OVERDUE_CASE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 4,
          operationCycle: '運用設定名1',
          status: '設定未完了',
          startedAt: '2020-01-01T00:00:00+09:00',
          endedAt: '2021-01-01T23:59:59+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === COMPLETE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 5,
          operationCycle: '運用設定名1',
          status: '終了',
          startedAt: '2023-01-02T00:00:00+09:00',
          endedAt: '2023-01-16T23:59:59+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === STRETCH_PLAN.INPROGRESS_FOR_RESEND_MAIL) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 6,
          operationCycle: '運用設定名1',
          status: '実施中',
          startedAt: '2023-02-13T00:00:00+09:00',
          endedAt: '2023-03-05T23:59:59+09:00',
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      stretchPlan: {
        id: 1,
        operationCycle: '運用設定名1',
        status: '設定完了',
        startedAt: '2050-01-01T00:00:00+09:00',
        endedAt: '2051-01-01T23:59:59+09:00',
      },
    },
    { status: 200 }
  );
};

export const stretchPlan = {
  get,
};
