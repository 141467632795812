import { isFeatHostName } from '~operation/utility/regExp';
import { VITE_APP_API_BASE_URL } from '~operation/env';
import { useFeatureFlag } from '~operation/composables/useFeatureFlags';
import { computed } from 'vue';
const { featureFlagData } = useFeatureFlag();

/**
 * 未公開機能の試験用フラグ
 * ローカル環境あるいはURLのhostnameが「feat-」から始まる場合trueを返す
 * @returns boolean
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isFeat = computed(() => {
  const isStg = /\/api\.stg\.stretch/.test(VITE_APP_API_BASE_URL);
  const isPrd = /\/api\.stretch\.motivation-cloudapp/.test(VITE_APP_API_BASE_URL);

  // 絶対に本番とstgでは出さないための判定
  if (isStg || isPrd) {
    return false;
  } else if (isFeatHostName(window.location.hostname) || window.location.hostname === 'localhost') {
    return true;
  } else {
    return false;
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isAppConfigFeatureFlag(flagName: string): boolean {
  const keyValue = featureFlagData[flagName] as boolean;
  // keyが登録されていない場合の処理
  if (typeof keyValue === 'undefined') {
    return false;
  }
  return keyValue;
}

/**
 * 【appConfigでのフラグの運用方法】
 * https://lmi-mcs.atlassian.net/wiki/spaces/MCSVER/pages/2917761089/SC+Feature+Flag
 * ・ appConfigFFと試験用FFの運用方法
 *
 * (例)
 * export const isFeatMultilingalizationFlag = computed(() => {
 *   return isAppConfigFeatureFlag('multilingalizationFlag') || isFeat.value;
 * })
 *
 * Ph1（開発初期）
 * export const isFeatHogeHoge = computed(() => {
 *   return isFeat.value;
 * })
 *
 * Ph2（開発中〜一部顧客開放時）
 * export const isFeatPagePage = computed(() => {
 *   return experimentToggle() || isFeat.value;
 * })
 *
 * Ph3（全顧客開放時）
 * 削除
 */

/**
 * ユーザー言語設定（多言語対応）
 */
export const isFeatUserLanguageSetting = computed(() => {
  return isAppConfigFeatureFlag('userLanguageSettingFlag') || isFeat.value;
});

/**
 * 【複数epicでのフラグの運用方法】
 * ・ epicごとでisFeat()をreturnする関数を作成し利用
 * ・ 関数の命名は「isFeat[Epic名]」で作成する
 *
 * (例)
 * export function isFeatHogeHoge() = computed(() => {
 *   return isFeat.value;
 * }
 *
 * export function isFeatPagePage() = computed(() => {
 *   return isFeat.value;
 * }
 */

/**
 * ORGユーザー設定画面の利用
 */
export const isFeatOrgUserSettingFlag = computed(() => {
  return isAppConfigFeatureFlag('orgUserSettingFlag') || isFeat.value;
});

/**
 * チケットサービス導入
 */
export const isFeatTicketService = computed(() => {
  return isAppConfigFeatureFlag('ticketServiceFlag');
});

/**
 * 共有用URL一覧
 */
export const isFeatSharedUrlListFlag = computed(() => {
  return true;
});
